import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './pricing.module.css'

const Pricing = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Pricing - Covert_Bear</title>
        <meta property="og:title" content="Pricing - Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <Link to="/" className={styles['navlink']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </Link>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <Link
                to="/"
                className={` ${styles['link']} ${projectStyles['anchor']} `}
              >
                Home
              </Link>
              <a
                href="#pricing_heading"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                Pricing
              </a>
              <Link
                to="/v-p-n"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                VPN
              </Link>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </Link>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </Link>
            </div>
          </div>
          <div className={styles['right-side']}>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Contact Us
            </Link>
          </div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container01']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['prices']}>
        <div className={styles['heading-container']}>
          <header data-role="Accordion" className={styles['header']}>
            <h1
              id="pricing_heading"
              className={` ${styles['text']} ${projectStyles['section-heading']} `}
            >
              Pricing
            </h1>
            <div className={styles['separator']}></div>
            <nav className={styles['nav']}>
              <div className={styles['container02']}>
                <a href="#vpn_price" className={styles['link09']}>
                  VPN
                </a>
                <a href="#windows_price" className={styles['link10']}>
                  Windows RDP
                </a>
                <a href="#linux_price" className={styles['link11']}>
                  Linux RDP
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="vpn_price" className={styles['heading-container1']}>
          <header data-role="Accordion" className={styles['header1']}>
            <h1
              className={` ${styles['text001']} ${projectStyles['section-heading']} `}
            >
              VPN
            </h1>
            <Link
              to="/v-p-n"
              className={` ${styles['navlink01']} ${projectStyles['button']} `}
            >
              <span className={styles['text002']}>Learn More</span>
            </Link>
          </header>
        </div>
        <div className={styles['separator1']}></div>
        <div className={styles['v-p-n-pricing']}>
          <div className={styles['container03']}>
            <div className={styles['container04']}>
              <div className={styles['container05']}>
                <span className={styles['text003']}>
                  <span>✔ Dedicated VPN Server in Select Location</span>
                </span>
                <span className={styles['text005']}>
                  <span>✔ Can change location free up to 3 times</span>
                </span>
                <span className={styles['text007']}>
                  <span>
                    ✔ Additional Servers at same
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text009']}>monthly</span>
                  <span> price of chosen plan*</span>
                </span>
              </div>
              <div className={styles['container06']}>
                <span className={styles['text011']}>
                  <span>✔ 500 GB Bandwidth</span>
                  <span>**</span>
                </span>
              </div>
            </div>
            <div className={styles['container07']}>
              <Link to="/contact1" className={styles['navlink02']}>
                <div className={styles['pricing-card']}>
                  <span className={styles['text014']}>Monthly Plan</span>
                  <div className={styles['container08']}>
                    <span className={styles['text015']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text018']}>12.99</span>
                    <span className={styles['text019']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text021']}>
                    <span>Billed monthly</span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink03']}>
                <div className={styles['pricing-card01']}>
                  <span className={styles['text023']}>2 Year Plan</span>
                  <span
                    className={` ${styles['text024']} ${projectStyles['card-text']} `}
                  >
                    MOST POPULAR
                  </span>
                  <div className={styles['container09']}>
                    <span className={styles['text025']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text028']}>7.99</span>
                    <span className={styles['text029']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text031']}>
                    <span>Billed biannualy</span>
                  </span>
                  <span
                    className={` ${styles['text033']} ${projectStyles['section-text']} `}
                  >
                    <span>
                      You Save
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text035']}>33%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink04']}>
                <div className={styles['pricing-card02']}>
                  <span className={styles['text037']}>1 Year Plan</span>
                  <div className={styles['container10']}>
                    <span className={styles['text038']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text041']}>9.99</span>
                    <span className={styles['text042']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text044']}>
                    <span>Billed annualy</span>
                  </span>
                  <span
                    className={` ${styles['text046']} ${projectStyles['section-text']} `}
                  >
                    <span>
                      You Save
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text048']}>25%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <span className={styles['text050']}>
              <span>
                * If you get the 2 Year Plan, every additional Server will cost
                only $8/month, billed
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text052']}>monthly</span>
              <span>, for the duration of the plan</span>
              <br></br>
              <span>
                Similarly, with the 1 Year Plan, every additional Server will
                cost $10/month, billed monthly, for the duration of the plan.
              </span>
              <br></br>
              <span>
                ** Additional Bandwidth costs $0.02 per GB or plan can be
                upgraded as needed. Contact us to discuss a suitable plan for
                you.
              </span>
            </span>
          </div>
        </div>
        <div className={styles['section-separator']}></div>
        <div id="windows_price" className={styles['heading-container2']}>
          <header data-role="Accordion" className={styles['header2']}>
            <h1
              className={` ${styles['text058']} ${projectStyles['section-heading']} `}
            >
              Windows RDP
            </h1>
            <Link
              to="/windows-r-d-p"
              className={` ${styles['navlink05']} ${projectStyles['button']} `}
            >
              <span className={styles['text059']}>Learn More</span>
            </Link>
          </header>
        </div>
        <div className={styles['separator2']}></div>
        <div className={styles['windows-pricing-low']}>
          <div className={styles['container11']}>
            <div className={styles['container12']}>
              <div className={styles['container13']}>
                <span className={styles['text060']}>
                  <span>✔ Windows Remote Desktop in Select Location</span>
                </span>
                <span className={styles['text062']}>
                  <span>✔ Can change location free up to 3 times</span>
                </span>
                <span className={styles['text064']}>
                  <span>
                    ✔ Additional RD at same
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text066']}>monthly</span>
                  <span> price of chosen plan*</span>
                </span>
              </div>
              <div className={styles['container14']}>
                <span className={styles['text068']}>
                  <span>✔ 1 CPU</span>
                </span>
                <span className={styles['text070']}>
                  <span>✔ 2 GB Memory</span>
                </span>
                <span className={styles['text072']}>
                  <span>✔ 2 TB Bandwidth**</span>
                </span>
              </div>
            </div>
            <div className={styles['container15']}>
              <Link to="/contact1" className={styles['navlink06']}>
                <div className={styles['pricing-card03']}>
                  <span className={styles['text074']}>Monthly Plan</span>
                  <div className={styles['container16']}>
                    <span className={styles['text075']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text078']}>17.99</span>
                    <span className={styles['text079']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text081']}>
                    <span>Billed monthly</span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink07']}>
                <div className={styles['pricing-card04']}>
                  <span className={styles['text083']}>2 Year Plan</span>
                  <span
                    className={` ${styles['text084']} ${projectStyles['card-text']} `}
                  >
                    MOST POPULAR
                  </span>
                  <div className={styles['container17']}>
                    <span className={styles['text085']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text088']}>12.99</span>
                    <span className={styles['text089']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text091']}>
                    <span>Billed biannualy</span>
                  </span>
                  <span
                    className={` ${styles['text093']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 28</span>
                    <span className={styles['text095']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink08']}>
                <div className={styles['pricing-card05']}>
                  <span className={styles['text097']}>1 Year Plan</span>
                  <div className={styles['container18']}>
                    <span className={styles['text098']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text101']}>14.99</span>
                    <span className={styles['text102']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text104']}>
                    <span>Billed annualy</span>
                  </span>
                  <span
                    className={` ${styles['text106']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 17</span>
                    <span className={styles['text108']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <span className={styles['text110']}>
              <span>
                * Pay the same monthly price of your current plan for new RDs
                until the end of the plan&apos;s duration.
              </span>
              <br></br>
              <span>
                ** Additional Bandwidth costs $0.02 per GB or plan can be
                upgraded as needed. Contact us to discuss a suitable plan for
                you.
              </span>
            </span>
          </div>
        </div>
        <div className={styles['windows-pricing-high']}>
          <div className={styles['container19']}>
            <div className={styles['container20']}>
              <div className={styles['container21']}>
                <span className={styles['text114']}>
                  <span>✔ Windows Remote Desktop in Select Location</span>
                </span>
                <span className={styles['text116']}>
                  <span>✔ Can change location free up to 3 times</span>
                </span>
                <span className={styles['text118']}>
                  <span>
                    ✔ Additional RD at same
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text120']}>monthly</span>
                  <span> price of chosen plan*</span>
                </span>
              </div>
              <div className={styles['container22']}>
                <span className={styles['text122']}>
                  <span>✔ 2 CPUs</span>
                </span>
                <span className={styles['text124']}>
                  <span>✔ 4 GB Memory</span>
                </span>
                <span className={styles['text126']}>
                  <span>✔ 3 TB Bandwidth**</span>
                </span>
              </div>
            </div>
            <div className={styles['container23']}>
              <Link to="/contact1" className={styles['navlink09']}>
                <div className={styles['pricing-card06']}>
                  <span className={styles['text128']}>Monthly Plan</span>
                  <div className={styles['container24']}>
                    <span className={styles['text129']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text132']}>27.99</span>
                    <span className={styles['text133']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text135']}>
                    <span>Billed monthly</span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink10']}>
                <div className={styles['pricing-card07']}>
                  <span className={styles['text137']}>2 Year Plan</span>
                  <span
                    className={` ${styles['text138']} ${projectStyles['card-text']} `}
                  >
                    MOST POPULAR
                  </span>
                  <div className={styles['container25']}>
                    <span className={styles['text139']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text142']}>22.99</span>
                    <span className={styles['text143']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text145']}>
                    <span>Billed biannualy</span>
                  </span>
                  <span
                    className={` ${styles['text147']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 18</span>
                    <span className={styles['text149']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink11']}>
                <div className={styles['pricing-card08']}>
                  <span className={styles['text151']}>1 Year Plan</span>
                  <div className={styles['container26']}>
                    <span className={styles['text152']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text155']}>24.99</span>
                    <span className={styles['text156']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text158']}>
                    <span>Billed annualy</span>
                  </span>
                  <span
                    className={` ${styles['text160']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 11</span>
                    <span className={styles['text162']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <span className={styles['text164']}>
              <span>
                * Pay the same monthly price of your current plan for new RDs
                until the end of the plan&apos;s duration.
              </span>
              <br></br>
              <span>
                ** Additional Bandwidth costs $0.02 per GB or plan can be
                upgraded as needed. Contact us to discuss a suitable plan for
                you..
              </span>
            </span>
          </div>
        </div>
        <div className={styles['section-separator1']}></div>
        <div id="linux_price" className={styles['heading-container3']}>
          <header data-role="Accordion" className={styles['header3']}>
            <h1
              className={` ${styles['text168']} ${projectStyles['section-heading']} `}
            >
              Linux RDP
            </h1>
            <Link
              to="/linux-r-d-p"
              className={` ${styles['navlink12']} ${projectStyles['button']} `}
            >
              <span className={styles['text169']}>Learn More</span>
            </Link>
          </header>
        </div>
        <div className={styles['separator3']}></div>
        <div className={styles['linux-pricing-low']}>
          <div className={styles['container27']}>
            <div className={styles['container28']}>
              <div className={styles['container29']}>
                <span className={styles['text170']}>
                  <span>✔ Windows Remote Desktop in Select Location</span>
                </span>
                <span className={styles['text172']}>
                  <span>✔ Can change location free up to 3 times</span>
                </span>
                <span className={styles['text174']}>
                  <span>
                    ✔ Additional RD at same
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text176']}>monthly</span>
                  <span> price of chosen plan*</span>
                </span>
              </div>
              <div className={styles['container30']}>
                <span className={styles['text178']}>
                  <span>✔ 1 CPU</span>
                </span>
                <span className={styles['text180']}>
                  <span>✔ 1 GB Memory</span>
                </span>
                <span className={styles['text182']}>
                  <span>✔ 1 TB Bandwidth**</span>
                </span>
              </div>
            </div>
            <div className={styles['container31']}>
              <Link to="/contact1" className={styles['navlink13']}>
                <div className={styles['pricing-card09']}>
                  <span className={styles['text184']}>Monthly Plan</span>
                  <div className={styles['container32']}>
                    <span className={styles['text185']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text188']}>
                      <span>14.99</span>
                      <span></span>
                    </span>
                    <span className={styles['text191']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text193']}>
                    <span>Billed monthly</span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink14']}>
                <div className={styles['pricing-card10']}>
                  <span className={styles['text195']}>2 Year Plan</span>
                  <span
                    className={` ${styles['text196']} ${projectStyles['card-text']} `}
                  >
                    MOST POPULAR
                  </span>
                  <div className={styles['container33']}>
                    <span className={styles['text197']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text200']}>9.99</span>
                    <span className={styles['text201']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text203']}>
                    <span>Billed biannualy</span>
                  </span>
                  <span
                    className={` ${styles['text205']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 33</span>
                    <span className={styles['text207']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink15']}>
                <div className={styles['pricing-card11']}>
                  <span className={styles['text209']}>1 Year Plan</span>
                  <div className={styles['container34']}>
                    <span className={styles['text210']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text213']}>11.99</span>
                    <span className={styles['text214']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text216']}>
                    <span>Billed annualy</span>
                  </span>
                  <span
                    className={` ${styles['text218']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 2</span>
                    <span className={styles['text220']}>0%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <span className={styles['text222']}>
              <span>
                * Pay the same monthly price of your current plan for new RDs
                until the end of the plan&apos;s duration.
              </span>
              <br></br>
              <span>
                ** Additional Bandwidth costs $0.02 per GB or plan can be
                upgraded as needed. Contact us to discuss a suitable plan for
                you.
              </span>
            </span>
          </div>
        </div>
        <div className={styles['linux-pricing-high']}>
          <div className={styles['container35']}>
            <div className={styles['container36']}>
              <div className={styles['container37']}>
                <span className={styles['text226']}>
                  <span>✔ Linux Remote Desktop in Select Location</span>
                </span>
                <span className={styles['text228']}>
                  <span>✔ Can change location free up to 3 times</span>
                </span>
                <span className={styles['text230']}>
                  <span>
                    ✔ Additional RD at same
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text232']}>monthly</span>
                  <span> price of chosen plan*</span>
                </span>
              </div>
              <div className={styles['container38']}>
                <span className={styles['text234']}>
                  <span>✔ 1 CPU</span>
                </span>
                <span className={styles['text236']}>
                  <span>✔ 2 GB Memory</span>
                </span>
                <span className={styles['text238']}>
                  <span>✔ 2 TB Bandwidth**</span>
                </span>
              </div>
            </div>
            <div className={styles['container39']}>
              <Link to="/contact1" className={styles['navlink16']}>
                <div className={styles['pricing-card12']}>
                  <span className={styles['text240']}>Monthly Plan</span>
                  <div className={styles['container40']}>
                    <span className={styles['text241']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text244']}>17.99</span>
                    <span className={styles['text245']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text247']}>
                    <span>Billed monthly</span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink17']}>
                <div className={styles['pricing-card13']}>
                  <span className={styles['text249']}>2 Year Plan</span>
                  <span
                    className={` ${styles['text250']} ${projectStyles['card-text']} `}
                  >
                    MOST POPULAR
                  </span>
                  <div className={styles['container41']}>
                    <span className={styles['text251']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text254']}>12.99</span>
                    <span className={styles['text255']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text257']}>
                    <span>Billed biannualy</span>
                  </span>
                  <span
                    className={` ${styles['text259']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 28</span>
                    <span className={styles['text261']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
              <Link to="/contact1" className={styles['navlink18']}>
                <div className={styles['pricing-card14']}>
                  <span className={styles['text263']}>1 Year Plan</span>
                  <div className={styles['container42']}>
                    <span className={styles['text264']}>
                      <span>$</span>
                      <span></span>
                    </span>
                    <span className={styles['text267']}>14.99</span>
                    <span className={styles['text268']}>
                      <span>/ month</span>
                    </span>
                  </div>
                  <span className={styles['text270']}>
                    <span>Billed annualy</span>
                  </span>
                  <span
                    className={` ${styles['text272']} ${projectStyles['section-text']} `}
                  >
                    <span>You Save 17</span>
                    <span className={styles['text274']}>%</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <span className={styles['text276']}>
              <span>
                * Pay the same monthly price of your current plan for new RDs
                until the end of the plan&apos;s duration.
              </span>
              <br></br>
              <span>
                ** Additional Bandwidth costs $0.02 per GB or plan can be
                upgraded as needed. Contact us to discuss a suitable plan for
                you.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={styles['section-separator2']}></div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link12']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
